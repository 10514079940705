@tailwind base;
@tailwind components;
@tailwind utilities;

.text-pretty{
    text-wrap: balance;
}

body.carousel-open .whatsapp-button {
    display: none !important;
  }

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@keyframes snowfall {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  10% {
    opacity: 0.8;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(calc(100vh + 10px));
    opacity: 0;
  }
}

@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.85; }
}

.animate-snowfall {
  animation: snowfall linear infinite;
}

@keyframes float {
  0%, 100% { transform: translateY(0px); }
  50% { transform: translateY(-4px); }
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

@keyframes bounce-slow {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-4px); }
}
.animate-bounce-slow {
  animation: bounce-slow 2s infinite;
}

.holiday-banner {
  position: relative;
}

.lightrope {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  margin: -15px 0 0 0;
  padding: 0;
  pointer-events: none;
  width: 100%;
}

.lightrope li {
  position: relative;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  width: 12px;
  height: 28px;
  border-radius: 50%;
  margin: 20px;
  display: inline-block;
  background: rgba(0, 247, 165, 1);
  box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 1);
  animation-name: flash-1;
  animation-duration: 2s;
}

.lightrope li:nth-child(2n+1) {
  background: rgba(0, 255, 255, 1);
  box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
  animation-name: flash-2;
  animation-duration: 0.4s;
}

.lightrope li:nth-child(4n+2) {
  background: rgba(247, 0, 148, 1);
  box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 1);
  animation-name: flash-3;
  animation-duration: 1.1s;
}

.lightrope li:nth-child(odd) {
  animation-duration: 1.8s;
}

.lightrope li:nth-child(3n+1) {
  animation-duration: 1.4s;
}

.lightrope li:before {
  content: "";
  position: absolute;
  background: #222;
  width: 10px;
  height: 9.3333333333px;
  border-radius: 3px;
  top: -4.6666666667px;
  left: 1px;
}

.lightrope li:after {
  content: "";
  top: -14px;
  left: 9px;
  position: absolute;
  width: 52px;
  height: 18.6666666667px;
  border-bottom: solid #222 2px;
  border-radius: 50%;
}

.lightrope li:last-child:after {
  content: none;
}

.lightrope li:first-child {
  margin-left: -40px;
}

@keyframes flash-1 {
  0%, 100% {
    background: rgba(0, 247, 165, 1);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 1);
  }
  50% {
    background: rgba(0, 247, 165, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
  }
}

@keyframes flash-2 {
  0%, 100% {
    background: rgba(0, 255, 255, 1);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 1);
  }
  50% {
    background: rgba(0, 255, 255, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
  }
}

@keyframes flash-3 {
  0%, 100% {
    background: rgba(247, 0, 148, 1);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 1);
  }
  50% {
    background: rgba(247, 0, 148, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
  }
}

@keyframes neonGlow {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
}

.neon-effect {
  background: linear-gradient(90deg, #ff0000, #00ff00, #0000ff, #ff0000);
  background-size: 400% 400%;
  animation: neonGlow 5s ease-in-out infinite, neonMove 20s linear infinite;
}

@keyframes neonMove {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 400% 50%;
  }
}

.santa-hat-container {
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

.santa-hat {
  position: absolute;
  transform: translateX(-50%) scaleX(-1);
}

@media (max-width: 640px) {
  .santa-hat {
    top: -8px;
    left: 122%;
    width: 40px;
    height: auto;
  }
}

@media (min-width: 641px) and (max-width: 1024px) {
  .santa-hat {
    top: -16px;
    left: 121%;
    width: 76px;
    height: auto;
  }
}

@media (min-width: 1025px) {
  .santa-hat {
    top: -16px;
    left: 122%;
    width: 76px;
    height: auto;
  }
}

