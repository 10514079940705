.custom-date-picker-container {
    width: 100%;
  }
  
  .react-datepicker-wrapper {
    display: block;
    width: 100%;
  }
  
  .react-datepicker__input-container {
    display: block;
    width: 100%;
  }
  
  .custom-datepicker {
    width: 100% !important;
  }

  .custom-datepicker-wrapper .custom-datepicker {
    margin-top: 0.75rem;
    height: 36px;
  }
  